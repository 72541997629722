import React from 'react';
import useNavigation from 'packages/use-navigation';
import { Typography } from '@mui/material';
import { ClassNameMap } from '@mui/styles/withStyles';
import useTranslations from '../translations';

function PrivacyLink({ classes }: { classes: ClassNameMap }) {
  const { Link } = useNavigation();
  const translations = useTranslations();
  return (
    <Typography className={classes.privacy}>
      {translations.PrivacyPrompt}
      <Link to="/privacy" className={classes.link}>
        {translations['Privacy Policy']}
      </Link>
    </Typography>
  );
}

export default PrivacyLink;
