import { Button } from '@mui/material';
import { ClassNameMap } from '@mui/styles/withStyles';
import React from 'react';

type GoogleButtonProps = {
  onClick: () => void;
  classes: ClassNameMap;
  children: React.ReactNode;
};
function GoogleButton({ onClick, classes, children }: GoogleButtonProps) {
  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={onClick}
      className={`${classes.button} ${classes.googleButton}`}
    >
      {children}
    </Button>
  );
}

export default GoogleButton;
