import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import EmailInput from 'packages/forms/email-field';
import SubmitButton from 'packages/forms/submit-button';
import { useAuthContext } from 'auth/context';
import { useNotify } from 'notifications-context';
import { CircularProgress, FormHelperText } from '@mui/material';
import { useLocalStorage } from 'helper-hooks';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles((theme: any) => ({
  button: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    width: '100%',
  },
}));
function EmailLogin({ setLoginError }:{ setLoginError: (error: string) => void }) {
  const classes = useStyles();
  const notify = useNotify();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { registerWithEmail } = useAuthContext();
  const emailError = !emailRegex.test(email);
  const { setItem } = useLocalStorage();
  const [success, setSuccess] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    registerWithEmail(email)
      .then(() => {
        setItem('emailForSignIn', email);
        notify({ message: 'Email verification link sent, Please check your inbox!', type: 'info', timeout: 15000 });
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoginError(error.message);
        setLoading(false);
      });
  };
  const submitDisabled: boolean = emailError;
  return (
    <div className={classes.root}>
      {success && <FormHelperText>Check your email for a verification link</FormHelperText>}
      <EmailInput
        value={email}
        onChange={setEmail}
        label="Email"
        required
        error={!!email && emailError}
      />
      <FormHelperText error>
        {!!email && emailError && 'Please provide a valid email'}
      </FormHelperText>
      <SubmitButton
        text="Sign In"
        onClick={handleLogin}
        className={classes.button}
        disabled={submitDisabled || loading || success}
      >
        {(loading && !success) && (
          <CircularProgress
            color="inherit"
            size={25}
            thickness={5}
          />
        )}
        {success ? 'Success!! Check your inbox' : 'Sign In'}
      </SubmitButton>

    </div>
  );
}

export default EmailLogin;
