function useTranslations() {
  return {
    'Privacy Policy': 'Privacy Policy',
    PrivacyPrompt: 'By using this App, you agree to the terms of our ',
    'Sign up with Google': 'Sign up with Google',
    'Already have an Account': 'Already have an Account?',
  };
}

export default useTranslations;
