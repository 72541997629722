import React, { useState } from 'react';
import GoogleIcon from 'react-icons/lib/fa/google';
import propTypes from 'prop-types';
import {
  Paper, Typography,
} from '@mui/material';
import { useAuthContext } from 'auth/context';
import PrivacyLink from 'components/sign-up/form/privacy-link';
import useNavigation from 'packages/use-navigation';
import GoogleButton from 'components/sign-up/form/google-button';
import useStyles from './styles';
import useTranslations from './translations';
import LoginError from './login-error';
import EmailLogin from './email';

function Login({ stay, finalUrl }) {
  const [loginError, setLoginError] = useState('');
  const classes = useStyles();
  const { Link, history } = useNavigation();
  const { signInWithGoogle } = useAuthContext();
  const translations = useTranslations();

  const continueWithGoogleHandler = () => {
    signInWithGoogle()
      .then(() => {
        if (!stay) {
          history.push(finalUrl);
        }
      }).catch((error) => {
        setLoginError(error.message);
      });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={0}>
        <PrivacyLink classes={classes} />
        <LoginError loginError={loginError} />
        <EmailLogin setLoginError={setLoginError} />
        <GoogleButton
          onClick={continueWithGoogleHandler}
          classes={classes}
        >
          <GoogleIcon className={classes.buttonIcon} />
          {translations['Login with Google']}
        </GoogleButton>
        <Typography
          color="primary"
          component={Link}
          to="/sign-up"
          className={classes.link}
        >
          {translations['Don\'t have an Account']}
        </Typography>
      </Paper>
    </div>
  );
}
Login.propTypes = {
  stay: propTypes.bool,
  finalUrl: propTypes.string,
};
Login.defaultProps = {
  stay: false,
  finalUrl: '/',
};

export default Login;
