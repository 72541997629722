import React from 'react';
import { Link } from 'packages/use-navigation/navigation-context';
import HomeIcon from '@mui/icons-material/Home';
import { Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import propTypes from 'prop-types';
import useAuth from 'packages/auth/use-auth';
import useApplet from 'packages/db/use-applet';
import Form from './form';
import MainView from '../settings/main-view';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    display: 'flex',
    alignItems: 'flex-end',
  },
  logout: {
    padding: '15px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(8),
    '& >:first-child': {
      minWidth: '360px',
    },
  },
  progress: {
    height: theme.spacing(2),
  },
}));

function Login({ stay, finalUrl }) {
  const classes = useStyles();
  const { uid } = useAuth();
  const user = useApplet(uid);
  if (uid && user) {
    return (
      <div className={classes.logout}>
        <MainView user={user} />
        <Link to="/">
          <Fab color="primary">
            <HomeIcon />
          </Fab>
        </Link>
      </div>
    );
  }

  return (
    <>
      <Form stay={stay} finalUrl={finalUrl} />
      <div className={classes.root}>
        <Fab
          aria-label="Home"
          component={Link}
          to="/"
          color="primary"
        >
          <HomeIcon />
        </Fab>
      </div>
    </>
  );
}
Login.propTypes = {
  stay: propTypes.bool,
  finalUrl: propTypes.string,
};
Login.defaultProps = {
  stay: false,
  finalUrl: '/',
};

export default Login;
