import React from 'react';
import { Typography } from '@mui/material';

type LoginErrorProps = {
  loginError: string;
};

function LoginError({ loginError }: LoginErrorProps) {
  return <Typography color="secondary">{loginError}</Typography>;
}

export default LoginError;
