function useTranslations() {
  return {
    'Privacy Policy': 'Privacy Policy',
    PrivacyPrompt: 'By using this App, you agree to the terms of our ',
    'Login with Google': 'Login with Google',
    'Don\'t have an Account': 'Don\'t have an account?',
  };
}

export default useTranslations;
