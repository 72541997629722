import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    gridArea: 'main',
    height: '90vh',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'inherit',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    width: '100%',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonIcon: {
    fontSize: 36,
    marginRight: theme.spacing(1),
  },
  googleButton: {
    backgroundColor: '#DB4437',
    color: 'white',
  },
  fbButton: {
    display: 'none',
    marginBottom: theme.spacing(2),
    backgroundColor: '#3C5A99',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3C5A85',
    },
  },
  privacy: {
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  link: {
    textDecoration: 'none',
  },
  typography: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
