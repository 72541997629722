import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
  Card, CardContent, Typography,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { loadAuth } from 'packages/firebase/auth';
import withUserAuth from 'packages/auth/withUserAuth';
import withNotify from 'notifications-context/consumer';
import useNavigation from 'packages/use-navigation';
import Image from 'components/image';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  username: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  media: {
    background: 'blue',
    height: '192px',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    minWidth: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  contentContent: {
    flexGrow: 1,
  },
  signOut: {
    alignSelf: 'flex-end',
  },
  passwordChangeRoot: {
    margin: theme.spacing(2),
  },
  passwordChangeForm: {
    width: '100%',
    margin: 'auto',
    '@media(min-width: 500px)': {
      width: '65%',
    },
    '@media(min-width: 700px)': {
      width: '50%',
    },
  },
  progress: { height: '16px' },
}));

function MainView({
  user: { image, name, _meta: { username } }, notify,
}) {
  const classes = useStyles();
  const { history } = useNavigation();
  async function handleSignOut() {
    const auth = loadAuth();
    auth.signOut()
      .then(() => {
        notify({ message: `Signed Out @${username}`, type: 'success' });
        history.replace('/');
      })
      .catch(() => {
        notify({ message: 'Something went wrong', type: 'failure' });
      });
  }
  return (
    <div style={{ gridArea: 'top' }}>
      <Card className={classes.root}>
        {
          (image && image.contentURL)
          && (
            <Image
              src={image.contentURL}
              className={classes.media}
              alt=""
            />
          )
        }
        <CardContent className={classes.content}>
          <Typography variant="h6" gutterBottom>{name}</Typography>
          <div className={classes.contentContent}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {`@${username}`}
            </Typography>
          </div>
          <Button
            color="secondary"
            className={classes.signOut}
            onClick={() => { handleSignOut(); }}
          >
            Sign Out
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
MainView.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
    }),
    _meta: PropTypes.shape({
      username: PropTypes.string.isRequired,
    }),
  }).isRequired,
  notify: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    providerData: PropTypes.arrayOf(PropTypes.shape({
      providerId: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

export default compose(
  withNotify,
  withUserAuth,
)(MainView);
